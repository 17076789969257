<template>
  <div>

    <v-card-text class="select_customer">
      <v-row class="select_customer mt-1" @click="setShowCheckout(false)">
        <v-col cols="2">
          <v-avatar size="56">
            <img width="80" src="@/assets/images/pages/default_room.png" alt="">
          </v-avatar>
        </v-col>
        <v-col>
          <v-row no-gutters>
            <v-col style="color: black;" cols="8">
              <h2><b style="color: black;">{{ getRoom.description }}</b></h2>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>{{ getRoom.category.description }}</v-col>
          </v-row>
        </v-col>
        <!-- <v-col class="text-right">
                <span style="font-size:16px">{{ getAccommodationValue.days }}x</span> <span class="text_total">R$ {{ formatMoney(getAccommodationValue.tariff) }}</span>
            </v-col> -->
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-virtual-scroll v-if="getItemsCheckout.length > 0" :items="getItemsCheckout" height="350" item-height="50">
      <template v-slot:default="{ item }">
        <v-list-item :key="item.id">
          <v-list-item-content>
            <v-row class="item_checkout" no-gutters>
              <v-col cols="2" v-if="ifResponsible">
                <v-checkbox v-if="visibleToResposible(item)" class="ml-2 mr-2" v-model="item.checked"
                  @click="addItemResponsible(item)" label="" color="primary" hide-details></v-checkbox>
              </v-col>
              <v-col cols="" @click="editItemCheckout(item)">
                <b style="color:#16b1ff;font-size: small;">{{ item.description }}</b>
              </v-col>
              <v-col cols="3" class="text-right" @click="editItemCheckout(item)">
                <span style="font-size:16px">{{ item.pivot.quantity }}x</span> <span class="text_total">R$ {{
                  formatMoney(item.sale_price) }}</span>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-virtual-scroll>
    <div v-else class="empty text-center pt-16">
      <v-img width="50" class="mx-auto" src="@/assets/images/pages/empty_cart.png"></v-img>
      Nenhum produto foi consumido
    </div>

    <v-divider></v-divider>
    <v-card-text>
      <v-row class="">
        <v-col class="text_total">
          Subtotal
        </v-col>
        <v-col color="black" class="text-right text_total "> R$ {{ formatMoney(getSubtotal) }}</v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="text_total">Impostos</v-col>
        <v-col class="text-right text_total"> R$ {{ formatMoney(getTaxation) }}</v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="text_total">Desconto</v-col>
        <v-col class="text-right text_total"> R$ {{ formatMoney(getDiscount.value) }}</v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="text_total">Adição</v-col>
        <v-col class="text-right text_total"> R$ {{ formatMoney(getAdditional.value) }}</v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="text_total"><b>Total</b></v-col>
        <v-col class="text-right text_total"><b> R$ {{ formatMoney(getTotalToPay) }}</b></v-col>
      </v-row>

      <v-divider class="mb-4 mt-4"
        v-if="(getpaymentMethods.length > 0 || getPaymentMethodsPartial.length > 0)"></v-divider>
      <div v-if="loadingPaymentPartial">
        <v-row>
          <v-col class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row no-gutters v-for="payment in getPaymentMethodsPartial" :key="payment.meyhod">
          <v-col class="text_total">
            <div style="white-space: nowrap;">
              <v-icon color="error" @click="showRemovePaymentPartial(payment)"> {{ icons.mdiTrashCanOutline }}</v-icon>
              {{ methodPayment(payment) }}
              <v-chip v-if="payment.partial" small color="warning">Pag. parcial</v-chip>
            </div>
          </v-col>
          <v-col class="text-right text_total"> R$ {{ formatMoney(payment.value) }}</v-col>
        </v-row>
        <v-row no-gutters v-for="payment in getpaymentMethods" :key="payment.meyhod">
          <v-col class="text_total">
            <div style="white-space: nowrap;">
              <v-icon color="error" @click="removePayment(payment)"> {{ icons.mdiTrashCanOutline }}</v-icon>
              {{ methodPayment(payment) }}
              <v-chip v-if="payment.partial" small color="warning">pagamento parcial</v-chip>
            </div>
          </v-col>
          <v-col class="text-right text_total"> R$ {{ formatMoney(payment.value) }}</v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-divider class=""></v-divider>
    <v-card class="d-flex align-end">
      <v-card-text>
        <v-row>
          <v-col class="pay text_total">
            <b>Pagar</b>
          </v-col>
          <v-col class="text-right pay text_total">
            <b>R$ {{ formatMoney(getToPay) }}</b>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pay text_total">
            <b :class="{ troco: (getChange > 0) }">Troco</b>
          </v-col>
          <v-col class="text-right pay text_total">
            <b :class="{ troco: (getChange > 0) }">R$ {{ formatMoney(getChange) }}</b>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" x-large outlined icon tile class="" color="primary">
                  <v-icon> {{ icons.mdiDotsHorizontal }}</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="showDialogDiscount()">
                  <v-list-item-title>Desconto</v-list-item-title>
                </v-list-item>
                <v-list-item @click="showDialogAdditional()">
                  <v-list-item-title>Adicionar Valor</v-list-item-title>
                </v-list-item>
                <v-list-item @click="showNoteSale()">
                  <v-list-item-title>Nota</v-list-item-title>
                </v-list-item>
                <v-list-item @click="showAttach()">
                  <v-list-item-title>Anexo</v-list-item-title>
                </v-list-item>
                <v-list-item @click="showDialogObservations()">
                  <v-list-item-title>Observações</v-list-item-title>
                </v-list-item>

              </v-list>
            </v-menu>

          </v-col>
          <v-col cols="10">
            <!-- btn pagamento adiatado na reserva -->
            <v-btn v-if="getOriginPartialPayment == 'reserve'" @click="showDialogSelectCash()" color="warning"
              :loading="loadingPartial" :disabled="loadingPartial" x-large block>Pag. Adiantado R$ {{
                formatMoney(sumMethodPayment) }}</v-btn>
            <!-- pagamento adiantado no checkin -->
            <v-btn v-if="getOriginPartialPayment == 'accommodation'" @click="sendPartialPayment()" color="warning"
              :loading="loadingPartial" :disabled="loadingPartial" x-large block>Pagamento Parcial R$ {{
                formatMoney(sumMethodPayment) }}</v-btn>
            <!-- pagamento checkout -->
            <v-btn v-if="getOriginPartialPayment == 'checkout'" @click="showDataNotaFiscal()" color="primary"
              :loading="loadingCheckout" :disabled="btnCheckout" x-large block>Cobrar R$ {{ formatMoney(getToPay)
              }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- DIALOG  NOTA  -->
    <v-dialog v-model="dialogNoteSale" width="600">

      <v-card>
        <BarTitleDialog title="Nota de Checkout" @close='dialogNoteSale = false' />
        <v-card-text>
          <!-- <v-row class="mt-3">
                    <v-col>
                        <v-select outlined label="Pagamento recebido por"></v-select>
                    </v-col>
                </v-row> -->
          <v-row no-gutters>
            <v-col>
              <v-textarea outlined v-model="note" name="" id="" cols="30" rows="5"
                label="Notas sobre de checkout"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col class="text-right">
              <v-btn text class="mr-2" x-large @click="dialogNoteSale = false">Cancelar</v-btn>
              <v-btn color="primary" x-large @click="saveNote()">Salvar</v-btn>

            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG  DESCONTO -->
    <v-dialog v-model="dialogDiscount" width="600">
      <v-card>
        <BarTitleDialog title="Desconto" @close='dialogDiscount = false' />
        <v-card-text>
          <v-row class="mt-3">
            <v-col>
              <vuetify-money outlined v-model="discount.value" label="Desconto"></vuetify-money>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-textarea outlined v-model="discount.discount_obs" name="" id="" cols="30" rows="5"
                label="Observação"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col class="text-right">
              <v-btn text class="mr-2" x-large @click="dialogDiscount = false">Cancelar</v-btn>
              <v-btn color="primary" x-large @click="saveDiscount()">Salvar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG  ADDITIONAL  -->
    <v-dialog v-model="dialogAdditional" width="600">
      <v-card>
        <BarTitleDialog title="Adicionar Valor" @close='dialogAdditional = false' />
        <v-card-text>
          <v-row class="mt-3">
            <v-col>
              <vuetify-money outlined v-model="additional.value" label="Valor"></vuetify-money>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-textarea outlined v-model="additional.additional_obs" name="" id="" cols="30" rows="5"
                label="Observação"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col class="text-right">
              <v-btn text class="mr-2" x-large @click="dialogAdditional = false">Cancelar</v-btn>
              <v-btn color="primary" x-large @click="saveAdditional()">Salvar</v-btn>

            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG  AUTHORIZATION  -->
    <v-dialog v-model="dialogAuthorization" width="600">
      <v-card>
        <BarTitleDialog title="Autorizar" @close='dialogAuthorization = false' />
        <v-card-text>
          <v-row class="mt-3">
            <v-col>
              <v-text-field v-model="admLogin.email" outlined label="Email" placeholder="Digite seu email"
                hide-details="auto" class="mb-6 mt-2" :error-messages="message_error_email" @keyup="validEmail" />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field outlined v-model="admLogin.password" label="Senha" type="password"
                placeholder="Digite sua senha" hide-details="auto" :error-messages="message_error_email"
                @keyup="validPassword" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col class="text-right">
              <v-btn text class="mr-2" x-large @click="dialogAuthorization = false">Cancelar</v-btn>
              <v-btn color="primary" x-large @click="requestAuthorization()">Autorizar</v-btn>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG  ANEXO  -->
    <v-dialog v-model="dialogAttachPayment" width="600">
      <v-card>
        <BarTitleDialog title="Anexo" @close='dialogAttachPayment = false' />
        <v-card-text>
          <v-row class="mt-3">
            <v-col>
              <v-file-input v-model="attach.attach" outlined rounded chips multiple label="Anexo"></v-file-input>
            </v-col>
            <v-col v-if="getPaymentAttach" cols="4" class="text-center">
              <v-btn :loading="loadingAttachDownload" rounded x-large color="secondary"
                @click="downloadAttach()"><v-icon>{{
                  icons.mdiDownload }}</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-textarea outlined v-model="attach.attach_obs" name="" id="" cols="30" rows="5"
                label="Observação"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col class="text-right">
              <v-btn text class="mr-2" :disabled="loadingAttach" x-large
                @click="dialogAttachPayment = false">Cancelar</v-btn>
              <v-btn color="primary" :loading="loadingAttach" :disabled="loadingAttach" x-large
                @click="saveAttach()">Salvar</v-btn>

            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG EDITAR ITEM DO CHECKOUT -->
    <dialog-edit-item-checkout v-model="dialogEditItemCheckout" :index="editItemCheckoutIndex" />


    <!-- DIALOG REMOVER PAGAMNETO PARCIAL -->
    <v-dialog v-model="dialogRemovePaymentPartial" width="600">
      <v-card>
        <BarTitleDialog title="Remover Pag. Adiantado" @close='dialogRemovePaymentPartial = false' />
        <v-card-text>
          <v-row class="mt-3">
            <v-col>
              <v-textarea v-model="justifyRemovePaymentPartial" label="Justificativa" rows="2" outlined></v-textarea>
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col class="text-right">
              <v-btn text class="mr-2" x-large @click="dialogRemovePaymentPartial = false">Cancelar</v-btn>
              <v-btn color="error" :loading="loadingRemovePaymentPartial" :disabled="loadingRemovePaymentPartial"
                x-large @click="removePaymentPartial()">Remover</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG SELECIONAR CAIXA PARA PAGAMENTO ADIANTADO -->
    <v-dialog v-model="dialogSelectCash" max-width="500px">
      <v-card>
        <BarTitleDialog @close="dialogSelectCash = false" title="CAIXA" />
        <v-card-title class="text-h5 text-center">
          <v-row no-gutters>
            <v-col>Qual caixa ficará associado !</v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <!-- <v-row>
          <v-col>
            <v-textarea v-model="justifyCancel" outlined label="Justificativa" rows="3"></v-textarea>
          </v-col>
        </v-row> -->
          <v-row>
            <v-col>
              <v-select v-model="partialReserveCashId" :loading="loadingOpenedCashs" :disabled="loadingOpenedCashs"
                outlined label="Caixa" :items="cashItems" item-value="id" item-text='text'></v-select>
            </v-col>
          </v-row>
          <v-row v-if="loadingUserAdmin">
            <v-col class="text-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large color="blue darken-1" text @click="dialogSelectCash = false">
            fechar
          </v-btn>
          <v-btn large color="primary" :loading="loadingCancel" @click="sendReservePartialPayment()">
            ok
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDataNotaFiscal" width="800">
      <v-card>
        <BarTitleDialog @close="dialogDataNotaFiscal = false" title="dados para nota fiscal" />
        <v-card-text>
          <v-row no-gutters>
            <v-col class="col-4">
              <v-select @change="dataNotaFiscal = ''" label="Tipo de Pessoa" outlined v-model='typeOfPerson'
                :items="[{ value: 'PF', text: 'FÍSICA' }, { value: 'PJ', text: 'JURÍDICA' }]">
              </v-select>
            </v-col>
            <v-col class="mx-6">
              <v-switch v-model="noShow" color="primary" label="NO-SHOW" hide-details></v-switch>
            </v-col>

          </v-row>
          <v-row no-gutters>
            <v-col class="col-8" v-if="typeOfPerson == 'PF'">
              <AutocompleteSearchGuest @change="test()" v-model="dataNotaFiscal"></AutocompleteSearchGuest>
            </v-col>
            <v-col class="col-8" v-if="typeOfPerson == 'PJ'">
              <AutocompleteSearchCompany @change="test()" v-model="dataNotaFiscal"></AutocompleteSearchCompany>
            </v-col>

            <v-col v-if="typeOfPerson == 'PF'">
              <v-text-field disabled class="ml-2" v-mask="'###.###.###-##'" v-model="dataNotaFiscal.cpf" outlined
                label="CPF" :hide-details="true"></v-text-field>
            </v-col>
            <v-col v-if="typeOfPerson == 'PJ'">
              <v-text-field disabled v-mask="'##.###.###/####-##'" class="ml-2" v-model="dataNotaFiscal.cpf_cnpj"
                outlined label="CPF/CNPJ" :hide-details="true"></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-text-field disabled v-model="dataNotaFiscal.address" outlined hide-details
                label="Endereço"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col cols="3">
              <v-text-field disabled v-model="dataNotaFiscal.number" outlined hide-details
                label="Número"></v-text-field>
            </v-col>
            <v-col class="ml-2">
              <v-text-field disabled v-model="dataNotaFiscal.complement" outlined hide-details
                label="Complemento"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col cols="12">
              <v-text-field disabled v-model="dataNotaFiscal.neighborhood" outlined hide-details
                label="Bairro"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col class="">
              <v-text-field disabled v-mask="'##.###-###'" v-model="dataNotaFiscal.zipcode" outlined hide-details
                label="CEP"></v-text-field>
            </v-col>
            <v-col cols="" class="ml-2">
              <v-text-field disabled outlined label="Cidade" v-model="cityNotaFiscal" hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col cols="">
              <v-text-field disabled outlined label="Estado" v-model="stateNotaFiscal" hide-details></v-text-field>
            </v-col>
            <v-col cols="6" class="ml-2">
              <v-text-field disabled outlined label="Pais" v-model="countryNotaFiscal" hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <!-- <v-col cols="3">
              <v-text-field disabled v-mask="'(##)####-####'" v-model="dataNotaFiscal.home_phone" outlined hide-details
                label="Fone Res."></v-text-field>
            </v-col> -->
            <v-col class="">
              <v-text-field disabled v-mask="'(##)####-####'" v-model="dataNotaFiscal.commercial_phone" outlined
                hide-details label="Fone Com."></v-text-field>
            </v-col>
            <v-col class="ml-2">
              <v-text-field disabled v-mask="'(##)#####-####'" v-model="dataNotaFiscal.cell_phone" outlined hide-details
                label="Celular"></v-text-field>
            </v-col>
            <v-col class="ml-2">
              <v-text-field disabled v-model="dataNotaFiscal.email" outlined hide-details label="Email"></v-text-field>
            </v-col>
            <!-- <v-col class="ml-2">
              <v-text-field disabled v-mask="'(##)####-####'" v-model="dataNotaFiscal.fax_phone" outlined hide-details
                label="Fax"></v-text-field>
            </v-col> -->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col class="text-right">
              <v-btn color="primary" @click="confirmCheckout()">ok</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogObservation" width="600">
      <v-card>
        <BarTitleDialog title="Observações" @close="dialogObservation = false"></BarTitleDialog>
        <v-card-text>
          <v-data-table hide-default-footer :headers="headers_observation" :items="accommodation_observations"
            class="elevation-1" dense hide-default-header>
            <template v-slot:no-data>
              <NoDataTable>Nenhuma observação disponível</NoDataTable>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col class="text-right">
              <v-btn text x-large class="mr-4" @click="dialogObservation = false">Fechar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  // mapState,
} from 'vuex'
import { mdiDotsHorizontal, mdiTrashCanOutline, mdiEyeOutline, mdiDownload } from '@mdi/js'
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import DialogEditItemCheckout from './DialogEditItemCheckout.vue'
import AutocompleteSearchGuest from '@/components/AutocompleteSearchGuest'
import AutocompleteSearchCompany from '@/components/AutocompleteSearchCompany'
export default {
  components: {
    BarTitleDialog,
    DialogEditItemCheckout,
    AutocompleteSearchGuest,
    AutocompleteSearchCompany
  },
  data: () => ({
    //dialodDataNotaFiscal
    dialogDataNotaFiscal: false,
    dataNotaFiscal: '', //{ guest: '' },
    noShow: false,
    stateNotaFiscal: '',
    cityNotaFiscal: '',
    countryNotaFiscal: '',
    typeOfPerson: 'PF',

    //Observações
    accommodation_observations: [],
    dialogObservation: false,
    headers_observation: [
      {
        text: 'Observações',
        sortable: false,
        align: 'start',
        value: 'description',
      },
      {
        text: '',
        align: 'end',
        value: 'action',
      },
    ],

    //Admin Status
    admRole: {},
    dialogAuthorization: false,
    message_error_email: '',
    message_error_password: '',

    //dialog seleciona caixa
    dialogSelectCash: false,
    partialReserveCashId: null,
    loadingOpenedCashs: false,
    cashItems: [],

    //dialog remover pagamento parcial
    itemRemovePaymentPartial: {},
    dialogRemovePaymentPartial: false,
    loadingRemovePaymentPartial: false,
    justifyRemovePaymentPartial: '',

    loadingUserAdmin: false,
    loadingCancel: false,
    loadingAttachDownload: false,
    loadingAttach: false,
    dialogAttachPayment: false,
    loadingPaymentPartial: false,
    loadingPartial: false,
    editItemCheckoutIndex: null,
    dialogEditItemCheckout: false,
    dialogNoteSale: false,
    dialogCancelSale: false,
    gratuity_active: false,
    loadingCheckout: false,
    dialogDiscount: false,
    dialogAdditional: false,
    discount: {
      discount_obs: '',
      value: '',
    },
    additional: {
      additional_obs: '',
      value: '',
    },
    admLogin: {
      email: '',
      password: '',
    },
    attach: {
      attach: null,
      attach_obs: '',
    },
    icons: {
      mdiDotsHorizontal,
      mdiTrashCanOutline,
      mdiEyeOutline,
      mdiDownload,
    },
  }),
  created() {
    this.loadPartialPayment()
    this.cashsOpen().then(response => {
      this.cashItems = response.data
    })
    this.loadCurrentRole()

  },
  computed: {
    ...mapGetters('checkout', [
      'getTaxation',
      'getAccommodationValue',
      'getAdditional',
      'getDiscount',
      'getNote',
      'remainingValue',
      'getChange',
      'getRoom',
      'getItemsCheckout',
      'getStage',
      'getpaymentMethods',
      'getSubtotal',
      'getTotalToPay',
      'getSelectecResposible',
      'activeBtnCheckout',
      'getOriginPartialPayment',
      'getPaymentMethodsPartial',
      'getToPay',
      'getPaymentAttach',
    ]),

    sumMethodPayment() {
      return this.getpaymentMethods.reduce((acc, method) => {
        return acc + parseFloat(method.value)
      }, 0)
    },

    btnCheckout() {
      if (this.loadingCheckout) {
        return true
      }
      return !(this.activeBtnCheckout <= 0)
    },

    note: {
      get() {
        return this.getNote
      },
      set(val) {
        this.setNote(val)
      },
    },

    itemsCheckout() {
      return this.checkout
    },

    emptyCheckout() {
      if (this.checkout.length > 0) {
        return false
      }
      return true
    },

    ifResponsible() {
      if (Object.keys(this.getSelectecResposible).length > 0) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    ...mapMutations('checkout', [
      'clearCheckout',
      'setAdditional',
      'setDiscount',
      'setStage',
      'removePaymentMethod',
      'setNote',
      'setShowCheckout',
      'addItemResponsible',
      'addPaymentMethod',
      'setPaymentMethodsPartial',
      'setRoom',
      'setDataNotaFiscal',
    ]),
    ...mapActions('checkout', [
      'saveUnpaid',
      'storeSaleOutstanding',
      'storeSale',
      'exportPdfCheckout',
      'storePartialPayment',
      'storeResevePartialPayment',
      'getPartialPayment',
      'getResevePartialPayment',
      'storeAttach',
      'getDownloadAttach',
      'destroyPaymentPartial',
      'getUser',
      'cashsOpen',
    ]),
    ...mapActions('select', ['loadAdmRole']),
    ...mapActions('busy', [
      'getAccommodationObservations'
    ]),
    test() {

      this.cityNotaFiscal = this.dataNotaFiscal.city.name
      this.stateNotaFiscal = this.dataNotaFiscal.state.name
      this.countryNotaFiscal = this.dataNotaFiscal.country.name
    },
    loadPartialPayment() {
      //se origem do pagamanto e checkout (pagamento parcial)
      if (this.getOriginPartialPayment == 'reserve') {
        //se origem for reserva carrega  pagamento adiantato
        this.getPartialPaymentReserve()
      } else {
        this.getPartialPaymentCheckout()
      }
    },
    downloadAttach() {
      this.loadingAttachDownload = true
      let attach_id = this.getRoom.current_accommodation.payment_attachment.id

      this.getDownloadAttach(attach_id)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'attach.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => {
          this.loadingAttachDownload = false
        })
    },
    getPartialPaymentCheckout() {
      //se for pagamneto parcial carrega os pagamentos registrado
      //  if (this.getFlagPartialPayment) {
      this.setPaymentMethodsPartial([])
      this.loadingPaymentPartial = true
      this.loadingPartial = true //btn
      this.getPartialPayment()
        .then(response => {
          const payments = response.data
          let methodPayments = []
          payments.forEach(payment => {
            let data = {
              payment_item_id: payment.id,
              id: payment.pix_id,
              method: payment.method,
              value: payment.value,
              partial: true,
            }
            methodPayments.push(data)
            // this.addPaymentMethod(data)
          })
          this.setPaymentMethodsPartial(methodPayments)
        })
        .finally(() => {
          this.loadingPaymentPartial = false
          this.loadingPartial = false //btn
        })
      // }
    },
    getPartialPaymentReserve() {
      //se for pagamneto parcial carrega os pagamentos registrado
      //  if (this.getFlagPartialPayment) {
      this.setPaymentMethodsPartial([])
      this.loadingPaymentPartial = true
      this.loadingPartial = true //btn
      this.getResevePartialPayment()
        .then(response => {
          const payments = response.data
          let methodPayments = []
          payments.forEach(payment => {
            let data = {
              payment_item_id: payment.id,
              id: payment.pix_id,
              method: payment.method,
              value: payment.value,
              partial: true,
            }
            methodPayments.push(data)
            // this.addPaymentMethod(data)
          })
          this.setPaymentMethodsPartial(methodPayments)
        })
        .finally(() => {
          this.loadingPaymentPartial = false
          this.loadingPartial = false //btn
        })
      // }
    },
    //ESCOLHER O CAIXA Q VAI FICA ASSOCIADO
    showDialogSelectCash() {
      //verifica se o usario é admin
      this.getUser().then(response => {
        if (response.data.role.name == 'Admin') {
          this.dialogSelectCash = true
        } else {
          this.sendReservePartialPayment()
        }
      })
    },
    sendReservePartialPayment() {
      this.loadingPartial = true
      let data = { cash_id: this.partialReserveCashId }
      this.storeResevePartialPayment(data)
        .then(() => {
          this.$toast.success('Pagamento realizado com sucesso !')
          this.$router.push('/manager_room')
        })
        .finally(() => {
          this.loadingPartial = false
        })
    },

    sendPartialPayment() {
      this.loadingPartial = true
      this.storePartialPayment()
        .then(() => {
          this.$toast.success('Pagamento realizado com sucesso !')
          this.$router.push('/manager_room')
        })
        .finally(() => {
          this.loadingPartial = false
        })
    },
    visibleToResposible(item) {
      if (item.responsible) {
        if (item.responsible.id == this.getSelectecResposible.id) {
          return true
        }
        return false
      } else {
        return true
      }
    },
    saveAttach() {
      this.loadingAttach = true
      this.storeAttach(this.attach)
        .then(response => {
          this.$toast.success('Arquivo anexado com sucesso !')
          this.attach.attach = null
          this.attach.attach_obs = ''
          this.dialogAttachPayment = false
          let room = this.getRoom
          room.current_accommodation.payment_attachment = response.data
          this.setRoom(room)
        })
        .finally(() => {
          this.loadingAttach = false
        })
    },
    methodPayment(payment) {
      switch (payment.method) {
        case 'debit_card':
          return 'Cartão Débito'
        case 'credit_card':
          return 'Cartão Crédito'
        case 'money':
          return 'Dinheiro'
        case 'other':
          return 'Outros'
        case 'pix':
          return 'Pix'
        case 'invoice':
          return 'Faturado'
        case 'b2b':
          return 'B2B'
      }
      return
    },
    showDialogDiscount() {
      this.dialogDiscount = true
    },
    showDialogAdditional() {
      this.dialogAdditional = true
    },
    // nextStage() {
    //     switch (this.getStage) {
    //         case 'new_sale':
    //             this.setStage('gratuity')
    //             break
    //         case 'gratuity':
    //             this.setStage('payment')
    //             break
    //         case 'payment':
    //             this.setStage('new_sale')
    //             break
    //     }
    // },
    saveDiscount() {
      this.setDiscount(this.discount)
      this.dialogDiscount = false
    },
    saveAdditional() {
      this.setAdditional(this.additional)
      this.dialogAdditional = false
    },
    editItemCheckout(item) {
      this.editItemCheckoutIndex = this.getItemsCheckout.indexOf(item)
      this.dialogEditItemCheckout = true
    },
    showRemovePaymentPartial(payment) {
      this.itemRemovePaymentPartial = payment
      if (this.admRole.authorization) {
        this.dialogRemovePaymentPartial = true
      } else {
        this.dialogAuthorization = true
      }
    },
    removePaymentPartial() {
      this.loadingRemovePaymentPartial = true
      const data = {
        justify: this.justifyRemovePaymentPartial,
        payment_item_id: this.itemRemovePaymentPartial.payment_item_id,
      }
      this.destroyPaymentPartial(data)
        .then(response => {
          this.dialogRemovePaymentPartial = false
          this.loadPartialPayment()
          this.$toast.success('Pagamento adiantado removido')
        })
        .finally(() => {
          this.loadingRemovePaymentPartial = false
          this.loadCurrentRole()
        })
    },
    removePayment(payment) {
      this.removePaymentMethod(payment)
    },
    showNoteSale() {
      this.dialogNoteSale = true
    },
    showAttach() {
      this.dialogAttachPayment = true
    },
    saveNote() {
      this.dialogNoteSale = false
    },
    // showCancelSale() {
    //     this.dialogCancelSale = true
    // },
    // confirmCancelSale() {
    //     this.cancelSale()
    //     this.dialogCancelSale = false
    // },
    // sendSaveUnpaid() {
    //     this.saveUnpaid().then((response) => {

    //     }).finally(() => {

    //     })

    // },
    // dialog pra dados da nota fiscal
    showDataNotaFiscal() {
      this.dialogDataNotaFiscal = true
    },
    confirmCheckout() {
      if (this.dataNotaFiscal == '' || this.dataNotaFiscal == null) {
        this.$toast.error('É obrigatório ter uma pessoa Física ou Jurídica para gerar Nota Fiscal')
        return false
      }
      this.dataNotaFiscal.type_of_person = this.typeOfPerson
      this.dataNotaFiscal.no_show = this.noShow
      this.setDataNotaFiscal(this.dataNotaFiscal)
      this.sendSale()
      this.dialogDataNotaFiscal = false
    },

    sendSale() {
      this.loadingCheckout = true
      this.storeSaleOutstanding()
        .then(response => {
          this.$toast.success('Checkout realizado com sucesso!')
          this.downloadPdfCheckout()
        })
        .finally(() => { })
    },
    partialPayment() { },
    downloadPdfCheckout() {
      this.$toast.info('Aguarde... , Gerando arquivo de checkout!', {
        duration: 0,
      })
      this.exportPdfCheckout()
        .then(response => {
          //mostra pdf
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'checkout.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
          this.$toast.clear()
        })
        .catch(() => {
          this.$toast.clear()
          this.$toast.error('Não foi possível gerar arquivo de checkout')
        })
        .finally(() => {
          this.clearCheckout()
          this.$router.push('/manager_room')
          this.loadingCheckout = false
        })
    },

    formatMoney(value) {
      if (value != null) {
        return value.toLocaleString('PT-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },


    //Autorização

    loadCurrentRole() {
      this.getUser().then(response => {
        if (response.data.role.name == 'Admin') {
          this.admRole.authorization = true
        } else {
          this.admRole.authorization = false
        }
      })
    },

    validEmail() {
      if (this.admLogin.email === '') {
        this.message_error_email = 'Obrigatório';
        return false;
      }
      this.message_error_email = '';
      return true;
    },
    validPassword() {
      if (this.admLogin.password === '') {
        this.message_error_password = 'Obrigatório';
        return false;
      }
      this.message_error_password = '';
      return true;
    },

    requestAuthorization() {
      if (!this.validEmail() || !this.validPassword()) return;
      var credentials = {
        email: this.admLogin.email,
        password: this.admLogin.password
      }
      this.loadAdmRole(credentials).then(response => {
        this.admRole = response.data;
        if (this.admRole.authorization) {
          this.dialogAuthorization = false;
          this.showRemovePaymentPartial(this.itemRemovePaymentPartial);
        } else {
          this.$toast.error(this.admRole.error);
        }

      }).catch(() => {
        this.$toast.error('Usuário não autorizado')
      }).finally(() => {
        this.admLogin.email = ''
        this.admLogin.password = ''
      })
    },
    showDialogObservations() {
      let accommodation_id = this.getRoom.current_accommodation.id
      this.getAccommodationObservations(accommodation_id).then(response => {
        this.accommodation_observations = response.data
      })
      this.dialogObservation = true
    },
  },
}
</script>

<style scoped>
.troco {
  color: #ff4c51;
}

.text_total {
  color: black;
  font-size: 16px;
}

.pay {
  color: black;
}

.select_customer {
  cursor: pointer;
}

.select_customer:hover {
  background: #fafafa;
}

.box_car_empty {
  margin-top: 80%;
}

.item_checkout {
  height: 50px;
  cursor: pointer;
}

.item_checkout:hover {
  background: #fafafa;
}

.empty {
  height: 350px;
  width: 100%;
}
</style>
